/**
 User selector
 */

import React from 'react';
import {useQuery} from '@apollo/client';
import USERS_LITE_GQL from './users-lite.graphql';
import Loading from "../../../ui/loading";
import Error from '../../../ui/error';
import '../../../ui/semantic-ui/style.scss';
import SemanticDropdown from "../../../ui/widgets/semantic-dropdown";
import {t} from "../../../translation";

const UserSelector = (props) => {
    const companyID = !props.companyid? null: props.companyid;
    const skip = companyID === null && !props.allowUseWithoutParentID;
    const {data, error, loading} = useQuery(USERS_LITE_GQL, {client: props.client, skip, variables: {companyID}});
    if(error) return <Error>{t(error.message)}</Error>;
    if(loading) return <Loading/>;
    const placeholder = props.placeholder ?? t(`Select ${props.multiple === false? 'user': 'users'}`);
    const customOptions = skip? []: data.result.map((entry) => ({key: entry.id, value: entry.id, text: `${entry.name} (${entry.email})`}));
    const _props = {...props};
    delete _props.allowUseWithoutParentID;
    return <SemanticDropdown {..._props} customOptions={customOptions} placeholder={placeholder}/>
};

export default React.memo(UserSelector);