/**
 * Command management
 */

import React, {useCallback, useMemo, useState} from 'react';
import {setDefaultValues, validityObjContainsFalse} from "../../common/utils/state";
import {isEmptyStr} from "../../common/utils/validation";
import {getNextRenderer, renderNestedQueries} from "../../common/utils/renderer";
import {isRtl, t, textAlignStyle} from "../../common/translation";
import CommandActionSelector from "./action-selector";
import CREATE_COMMAND_GQL from './create.graphql';
import RUN_COMMAND_GQL from './run.graphql';
import {submitMutation} from "../../common/http/mutate";
import Success from "../../common/ui/success";
import Error from "../../common/ui/error";
import Loading from "../../common/ui/loading";
import TrackerGroupsSelector from "../../common/ui/widgets/tracker-groups-selector";
import TrackersSelector from "../../common/ui/widgets/trackers-selector";
import CommandSelector from "./command-selector";
import COMMANDS_GQL from './commands.graphql';
import CommandRenameDelete from "./rename-delete";
import ReactDOM from "react-dom";
import {isDistributor, isUser} from "../../common/auth/guard/check";
import UserSelector from "../../common/ui/widgets/user-selector";

const FIELDS = ['commandAction', 'commandName', 'commandContent', 'commandID', 'assignmentType', 'groupIDs', 'trackerIDs', 'userID'];

const CommandManagement = ({apolloClient, RAlert, footerContainer}) => {
    const mainFormValuesOverride = useMemo(() => ({groupIDs: [], trackerIDs: []}), []);
    const [mainFormValues, setMainFormValues] = useState(setDefaultValues(FIELDS, {}, mainFormValuesOverride));
    const [mainFormValidity, setMainFormValidity] = useState(setDefaultValues(FIELDS, {}, {}, true));
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);

    /**
     * On main form change
     */
    const onMainFormChange = useCallback(({target}) => {
        const values = {...mainFormValues, [target.id]: target.value};
        if(target.id === 'userID')
        {
            values.trackerIDs = [];
        }
        setMainFormValues(values);
    }, [mainFormValues]);

    /**
     * Resets form
     */
    const resetForm = useCallback(() => {
        setMainFormValues(setDefaultValues(FIELDS, {}, mainFormValuesOverride));
    }, [mainFormValuesOverride]);

    /**
     * On validation
     */
    const validateFields = () => {
        const formState = {...mainFormValidity};
        formState.commandAction = !isEmptyStr(mainFormValues.commandAction);
        formState.commandName = mainFormValues.commandAction !== 'create' || !isEmptyStr(mainFormValues.commandName);
        formState.commandContent = !['create', 'run'].includes(mainFormValues.commandAction) || !isEmptyStr(mainFormValues.commandContent);
        formState.commandID = mainFormValues.commandAction !== 'run-saved' || !isEmptyStr(mainFormValues.commandID);
        formState.assignmentType = isDistributor() || !['run', 'run-saved'].includes(mainFormValues.commandAction) || !isEmptyStr(mainFormValues.assignmentType);
        formState.groupIDs = isDistributor() || !['run', 'run-saved'].includes(mainFormValues.commandAction) || mainFormValues.assignmentType !== 'group' || mainFormValues.groupIDs.length > 0;
        if(isUser())
        {
            formState.trackerIDs = !['run', 'run-saved'].includes(mainFormValues.commandAction) || mainFormValues.assignmentType !== 'tracker' || mainFormValues.trackerIDs.length > 0;
        }
        else {
            formState.trackerIDs = !['run', 'run-saved'].includes(mainFormValues.commandAction) || mainFormValues.trackerIDs.length > 0;
        }
        formState.userID = isUser() || !isEmptyStr(mainFormValues.userID);
        setMainFormValidity(formState);
        return !validityObjContainsFalse(formState);
    };
    /**
     * On apply click
     */
    const onApplyClick = async () => {
        if(!validateFields())
        {
            return;
        }
        setErrorMessage(null);
        setSuccessMessage(null);
        setIsLoading(true);
        let targetGql;
        const variables = {
            payload: {}
        };
        if(mainFormValues.commandAction === 'create')
        {
            targetGql = CREATE_COMMAND_GQL;
            variables.payload.name = mainFormValues.commandName.trim();
        }
        if(['create', 'run'].includes(mainFormValues.commandAction))
        {
            variables.payload.content = mainFormValues.commandContent.trim();
        }
        if(mainFormValues.commandAction === 'run-saved')
        {
            variables.payload.commandID = mainFormValues.commandID;
        }
        if(['run', 'run-saved'].includes(mainFormValues.commandAction))
        {
            targetGql = RUN_COMMAND_GQL;
            if(isUser())
            {
                variables.payload.groupIDs = mainFormValues.assignmentType === 'group'? mainFormValues.groupIDs: null;
                variables.payload.trackerIDs = mainFormValues.assignmentType === 'tracker'? mainFormValues.trackerIDs: null;
            }
            else if(isDistributor())
            {
                variables.payload.trackerIDs = mainFormValues.trackerIDs;
            }
        }
        const params = [
            apolloClient,
            targetGql,
            variables,
            setErrorMessage,
            setIsLoading
        ];
        if(mainFormValues.commandAction === 'create')
        {
            params.push([{query: COMMANDS_GQL}]);
        }
        const {data, error} = await submitMutation(...params);
        if(!error)
        {
            if(mainFormValues.commandAction === 'create')
            {
                setSuccessMessage(t('Command created successfully'));
            }
            else if(['run', 'run-saved'].includes(mainFormValues.commandAction))
            {
                setSuccessMessage(t('Command is scheduled to run on %s trackers, see "View Results" to track status').replace('%s', data.result));
            }
            resetForm();
        }
    };
    /**
     * Footer
     */
    const Footer = () => {
        return (
            <div className="row">
                <div className="input-group justify-content-center">
                    <button type="button" className={`btn btn-outline-secondary ${isRtl()? 'ms-3': ''}`} onClick={() => RAlert.close()}>
                        <i className="bx bx-window-close"/>{t('Close')}
                    </button>
                    {
                        mainFormValues.commandAction !== '' && mainFormValues.commandAction !== 'manage' &&
                        <button className={`btn btn-success ${!isRtl()? 'ms-3': ''}`} onClick={onApplyClick}>
                            {
                                !isLoading &&
                                <>
                                    <i className="bx bx-check-circle"/>
                                    {t('Apply')}
                                </>
                            }
                            {
                                isLoading && <Loading/>
                            }
                        </button>
                    }
                </div>
            </div>
        );
    };
    /**
     * Renders card
     */
    const renderCard = (args, prevData, ...next) => {
        return (
            <>
                {getNextRenderer(args, prevData, ...next)}
                {ReactDOM.createPortal(<Footer/>, footerContainer)}
            </>
        );
    };
    /**
     * Renders form
     */
    const renderMain = (args, prevData, ...next) => {
        return (
            <div className="animated fadeIn">
                <div className="form-group p-2">
                    <div className="row">
                        {/* commandAction */}
                        <div className="col-md-4" style={textAlignStyle()}>
                            <label htmlFor="commandAction" className="form-label required">{t('Select action')}</label>
                            <CommandActionSelector domID={'commandAction'} selectedValues={mainFormValues.commandAction} onValueChange={onMainFormChange}
                                              client={apolloClient} isInvalid={!mainFormValidity.commandAction}/>
                            {
                                mainFormValues.commandAction === 'create' &&
                                <small className="text-muted">
                                    {t('Save a command to be executed later')}
                                </small>
                            }
                            {
                                mainFormValues.commandAction === 'run' &&
                                <small className="text-muted">
                                    {t('Run a command directly without having to save it')}
                                </small>
                            }
                            {
                                mainFormValues.commandAction === 'run-saved' &&
                                <small className="text-muted">
                                    {t('Run a previously saved command')}
                                </small>
                            }
                            {
                                mainFormValues.commandAction === 'manage' &&
                                <small className="text-muted">
                                    {t('Rename or delete a command')}
                                </small>
                            }
                        </div>
                        {/* commandName */}
                        {
                            mainFormValues.commandAction === 'create' &&
                            <div className="col-md-4" style={{...textAlignStyle()}}>
                                <label htmlFor="commandName" className="form-label required">{t(`Command's name`)}</label>
                                <input type="text" id={'commandName'} className={'form-control' + (!mainFormValidity.commandName? ' is-invalid': '')}
                                       value={mainFormValues.commandName} onChange={onMainFormChange}/>
                                <small className="text-muted">{t('The name of the command to save')}</small>
                            </div>
                        }
                        {/* commandContent */}
                        {
                            ['create', 'run'].includes(mainFormValues.commandAction) &&
                            <div className="col-md-4" style={{...textAlignStyle()}}>
                                <label htmlFor="commandContent" className="form-label required">{t(`Command to run`)}</label>
                                <input type="text" id={'commandContent'} className={'form-control' + (!mainFormValidity.commandContent? ' is-invalid': '')}
                                       value={mainFormValues.commandContent} onChange={onMainFormChange} placeholder={t('Example: getinfo')}/>
                                <small className="text-muted">{t('The command that will be sent to the device')}</small>
                            </div>
                        }
                        {/* commandID */}
                        {
                            ['run-saved', 'manage'].includes(mainFormValues.commandAction) &&
                            <div className="col-md-4" style={{...textAlignStyle()}}>
                                <label htmlFor="commandID" className="form-label required">
                                    {
                                        mainFormValues.commandAction === 'run-saved' &&
                                        t('Command to run')
                                    }
                                    {
                                        mainFormValues.commandAction === 'manage' &&
                                        t('Select a command to rename or delete')
                                    }
                                </label>
                                <CommandSelector domID={'commandID'} selectedValues={mainFormValues.commandID} onValueChange={onMainFormChange}
                                                       client={apolloClient} isInvalid={!mainFormValidity.commandID}/>
                            </div>
                        }
                    </div>
                </div>
                <hr/>
                {
                    isUser(['view-commands']) && ['run', 'run-saved'].includes(mainFormValues.commandAction) &&
                    <div className="form-group p-2">
                        <div className="row">
                            {/* assignmentType */}
                            <div className="col-md-4" style={textAlignStyle()}>
                                <label htmlFor="assignmentType" className="form-label required">{t('Applies to')}</label>
                                <select id={'assignmentType'} className={'form-control' + (!mainFormValidity.assignmentType? ' is-invalid': '')}
                                        onChange={onMainFormChange} value={mainFormValues.assignmentType}>
                                    <option value={''}>{t('Send this command to')}</option>
                                    <option value={'group'}>{t('One or more group')}</option>
                                    <option value={'tracker'}>{t('One or more tracker')}</option>
                                </select>
                            </div>
                            {/* groupIDs */}
                            {
                                mainFormValues.assignmentType === 'group' &&
                                <div className="col-md-4" style={textAlignStyle()}>
                                    <label htmlFor="groupIDs" className="form-label">{t('Groups')}</label>
                                    <TrackerGroupsSelector client={apolloClient} domID={'groupIDs'} selectedValues={mainFormValues.groupIDs}
                                                           onValueChange={onMainFormChange} isInvalid={!mainFormValidity.groupIDs}/>
                                </div>
                            }
                            {/* trackerIDs */}
                            {
                                mainFormValues.assignmentType === 'tracker' &&
                                <div className="col-md-4" style={textAlignStyle()}>
                                    <label htmlFor="trackerIDs" className="form-label">{t('Trackers')}</label>
                                    <TrackersSelector client={apolloClient} domID={'trackerIDs'} onValueChange={onMainFormChange} selectedValues={mainFormValues.trackerIDs}
                                                      isInvalid={!mainFormValidity.trackerIDs}/>
                                </div>
                            }
                        </div>
                    </div>
                }
                {
                    isDistributor(['sign-in-as-users']) && ['run', 'run-saved'].includes(mainFormValues.commandAction) &&
                    <div className="form-group p-2">
                        <div className="row">
                            <div className="col-md-4" style={textAlignStyle()}>
                                <label htmlFor="userID" className="form-label">{t('User')}</label>
                                <UserSelector domID={'userID'} selectedValues={mainFormValues.userID}
                                              allowUseWithoutParentID={true} multiple={false}
                                              onValueChange={onMainFormChange} isInvalid={!mainFormValidity.userID}
                                              client={apolloClient}/>
                            </div>
                            <div className="col-md-4" style={textAlignStyle()}>
                            <label htmlFor="trackerIDs" className="form-label">{t('Trackers')}</label>
                                    <TrackersSelector client={apolloClient} domID={'trackerIDs'}
                                                      onValueChange={onMainFormChange}
                                                      skip={!mainFormValues.userID}
                                                      filter={{userID: mainFormValues.userID}}
                                                      selectedValues={mainFormValues.trackerIDs}
                                                      isInvalid={!mainFormValidity.trackerIDs}/>
                                </div>
                            </div>
                        </div>
                        }
                        {
                            mainFormValues.commandAction === 'manage' && mainFormValues.commandID !== '' &&
                    <CommandRenameDelete key={`manage-${mainFormValues.commandID}`} apolloClient={apolloClient}
                                         commandID={mainFormValues.commandID}
                                         setSuccessMessage={setSuccessMessage} resetForm={resetForm}/>
                }
                {
                    errorMessage !== null &&
                    <div className="form-group p-2">
                        <div style={{...textAlignStyle()}}>
                            <Error>{errorMessage}</Error>
                        </div>
                    </div>
                }
                {
                    successMessage !== null &&
                    <div className="form-group p-2">
                        <div style={{...textAlignStyle()}}>
                            <Success>{successMessage}</Success>
                        </div>
                    </div>
                }
                {getNextRenderer(args, prevData, ...next)}
            </div>
        );
    };
    return (
        renderNestedQueries(
            {},
            {},
            renderCard,
            renderMain
        )
    );
};

export default CommandManagement;