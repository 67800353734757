/**
 Command action-selector
 */

import React from 'react';
import '../../common/ui/semantic-ui/style.scss';
import SemanticDropdown from "../../common/ui/widgets/semantic-dropdown";
import {t} from "../../common/translation";
import {isDistributor, isUser} from "../../common/auth/guard/check";
import {FLEET_PROFILE} from "../../common/auth/profiles";

const CommandActionSelector = (props) => {
    const actions = [];
    if(isUser(['create-commands'], FLEET_PROFILE) || isDistributor(['sign-in-as-users']))
    {
        actions.push({value: 'create', label: 'Create command'});
    }
    if(isUser(['create-commands'], FLEET_PROFILE) || isDistributor(['sign-in-as-users']))
    {
        actions.push({value: 'run', label: 'Run command'});
    }
    //view-commands permission already required by parent component
    actions.push({value: 'run-saved', label: 'Run saved command'});
    if(isUser(['delete-commands'], FLEET_PROFILE) || isDistributor(['sign-in-as-users']))
    {
        actions.push({value: 'manage', label: 'Manage saved commands'});
    }

    const placeholder = t(`Select from the list`);
    const customOptions = actions.map((entry) => ({key: entry.value, value: entry.value, text: t(entry.label)}))
    return <SemanticDropdown {...props} customOptions={customOptions} placeholder={placeholder} multiple={false}/>
};

export default CommandActionSelector;